export const SET_APPROVWLISTS = "setApproveLists";
export const SET_APPROVWLISTS_TOTAL = "setApproveListsTotal";
export const SET_LOADING = "setLoading";

export default {
  [SET_APPROVWLISTS](state, approve_lists) {
    state.approve_lists = approve_lists;
  },
  [SET_APPROVWLISTS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
