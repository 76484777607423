import {
    SET_LOADING,SET_QUOTATIONS_TOTAL,SET_QUOTATION,SET_QUOTATIONS
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchMgmtQuotationForms({ commit },  params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("mgmt/quotations",params)
        .then((result) => {
          result =result.data;
          commit(SET_QUOTATIONS, result?.data ?? []);
          commit(SET_QUOTATIONS_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          console.log('error response', response);
          commit(SET_LOADING, false);
        });
  },
  async fetchMgmtQuotationForm({ commit }, id) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get(`mgmt/quotations/${id}`)
        .then((result) => {
          commit(SET_QUOTATION, result?.data ?? {});
          commit(SET_LOADING, false);
          return result;
        })
        .catch(({response}) => {
          console.log('error response', response);
          commit(SET_QUOTATION, { success: false });

        });

  },
  async updateMgmtQuotationForm({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.put("mgmt/quotations/"+params.apply_form_id, params)
        .then((result) => {
          result = result.data;

          return result;
        })
        .catch(({response}) => {
          console.log('error response', response.data);
          return response.data;
        });
  },
};
