import {
    SET_LOADING, SET_REVIEWS, SET_REVIEWSALL, SET_REVIEW, SET_REVIEWS_TOTAL, SET_REVIEWS_ALL_TOTAL, SET_SUBORDINATES, SET_SUBORDINATE, SET_SUBORDINATES_TOTAL, SET_USERDASHBOARD, SET_RECONFIRMDASHBOARD, SET_REVIEWTRIALS, SET_REVIEWTRIAL, SET_REVIEWTRIALSFINAL, SET_REVIEWTRIALFINAL, SET_REVIEWTARGET, SET_REVIEWLASTMONTHTARGET, SET_REVIEWDEPTS, SET_REVIEWDEPT, SET_REVIEWDEPTGOAL, SET_REVIEWDEPTLASTMONTHGOAL, SET_EVERYONECOMMENTBYID, SET_REVIEWTARGETBYID, SET_TO_ALL_USER
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
    async fetchReviews({ commit },  params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("review/reviews",params)
            .then((result) => {
                commit(SET_REVIEWS, result?.data ?? []);
                commit(SET_REVIEWS_TOTAL, result?.total ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({response}) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
    async fetchReviewsAll({ commit },  params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("review/review_all",params)
            .then((result) => {
                commit(SET_REVIEWSALL, result?.data.data ?? []);
                commit(SET_REVIEWS_ALL_TOTAL, result?.data.total ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({response}) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
    async fetchReview({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.get(`review/reviews/${params.id}`)
            .then((result) => {
                commit(SET_REVIEW, result?.data ?? {});
                commit(SET_LOADING, false);
                return result;
            })
            .catch(({response}) => {
                console.log('error response', response.data);
                commit(SET_REVIEW, { success: false,message: response.data.message });

            });

    },
    async scoreReview({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put(`review/reviews/${params.id}`, params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response.data);
                return response.data;
            });
    },
    async calculateScore({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put(`review/review_all/${params.id}`, params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response.data);
                return response.data;
            });
    },

    async setReviewGoal({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put(`review/review_user_target/${params.id}`, params)
            // .then((result) => {
            //     result = result.data;
            //     return result;
            // })
            // .catch(({ response }) => {
            //     console.log('error response', response.data);
            //     return response.data;
            // });
    },

    async fetchSubordinates({ commit },  params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("review/review_subordinates",params)
            .then((result) => {
                commit(SET_SUBORDINATES, result?.data ?? []);
                commit(SET_SUBORDINATES_TOTAL, result?.total ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({response}) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
    async fetchSubordinate({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query(`review/review_subordinates/${params.id}`, params)
            .then((result) => {
                commit(SET_SUBORDINATE, result?.data ?? {});
                commit(SET_LOADING, false);
                return result;
            })
            .catch(({response}) => {
                console.log('error response', response.data);
                commit(SET_SUBORDINATE, { success: false,message: response.data.message });

            });

    },

    async fetchUserDashboard({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("review/review_user_dashboard", params)
            .then((result) => {
                commit(SET_USERDASHBOARD, result?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
    async fetchReconfirmDashboard({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("review/review_trial_dashboard", params)
            .then((result) => {
                commit(SET_RECONFIRMDASHBOARD, result?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },

    async fetchReviewTrials({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("review/review_trial", params)
            .then((result) => {
                commit(SET_REVIEWTRIALS, result?.data?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_REVIEWTRIALS, false);
            });
    },
    async fetchReviewTrial({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.get(`review/review_trial/${params.id}`)
            .then((result) => {
                commit(SET_REVIEWTRIAL, result?.data ?? {});
                commit(SET_LOADING, false);
                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response.data);
                commit(SET_REVIEWTRIAL, { success: false, message: response.data.message });
            });
    },

    async fetchReviewTrialsFinal({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("review/review_trial_final", params)
            .then((result) => {
                commit(SET_REVIEWTRIALSFINAL, result?.data?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_REVIEWTRIALSFINAL, false);
            });
    },
    async fetchReviewTrialFinal({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.get(`review/review_trial_final/${params.id}`)
            .then((result) => {
                commit(SET_REVIEWTRIALFINAL, result?.data ?? {});
                commit(SET_LOADING, false);
                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response.data);
                commit(SET_REVIEWTRIALFINAL, { success: false, message: response.data.message });
            });
    },
    async putReviewTrialFinal({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put(`review/review_trial_final/${params.id}`, params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response.data);
                return response.data;
            });
    },

    async fetchReviewTarget({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query(`review/review_user_target/${params.id}`)
            .then((result) => {
                commit(SET_REVIEWTARGET, result?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_REVIEWTARGET, false);
            });
    },
    async fetchReviewTargetById({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query(`review/review_user_target/${params.id}/month`)
            .then((result) => {
                commit(SET_REVIEWTARGETBYID, result?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_REVIEWTARGETBYID, false);
            });
    },
    async fetchReviewLastMonthTarget({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query(`review/review_user_target/${params.id}/last_month`)
            .then((result) => {
                commit(SET_REVIEWLASTMONTHTARGET, result?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_REVIEWLASTMONTHTARGET, false);
            });
    },
    async setReviewTarget({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put(`review/review_user_target/${params.id}`)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response.data);
                return response.data;
            });
    },
    async uploadReviewTarget({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put(`review/review_user_target/${params.id}/target`, params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response.data);
                return response.data;
            });
    },
    async uploadReviewTargetById({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put(`review/review_user_target/${params.id}/month_target`, params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response.data);
                return response.data;
            });
    },

    async fetchReviewDepts({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("review/review_depts", params)
            .then((result) => {
                commit(SET_REVIEWDEPTS, result?.data?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
    async fetchReviewDept({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query(`review/review_depts/${params.id}`)
            .then((result) => {
                commit(SET_REVIEWDEPT, result?.data?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
    async setReviewDeptGoal({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put(`review/review_depts_target/${params.id}`, params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response.data);
                return response.data;
            });
    },
    async scoreReviewDeptGoal({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put(`review/review_depts/${params.id}`, params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response.data);
                return response.data;
            });
    },
    async fetchReviewDeptGoal({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query(`review/review_depts_target/${params.id}`)
            .then((result) => {
                commit(SET_REVIEWDEPTGOAL, result?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
    async fetchReviewDeptLastMonthGoal({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query(`review/review_depts_target/${params.id}/last_month`)
            .then((result) => {
                commit(SET_REVIEWDEPTLASTMONTHGOAL, result?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },

    async fetchToEveryoneById({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query(`review/review_user_all/${params.id}`, params)
            .then((result) => {
                commit(SET_EVERYONECOMMENTBYID, result?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
    async toAllUser({ commit }, params) {
        commit(SET_LOADING, true, { root: true });
        ApiService.setHeader();
        return await ApiService.post("review/review_say_company", params)
            .then((result) => {
                // console.log(result)
                // result = result.data;
                return result;
            })
            .catch(({response}) => {
                // console.log('error response', response.data);
                return response.data;
            });
    },
    async fetchToAllUser({ commit }, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query(`review/review_say_company`, params)
            .then((result) => {
                commit(SET_TO_ALL_USER, result?.data?.data ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
    async toEveryone({ commit }, params) {
        commit(SET_LOADING, true, { root: true });
        ApiService.setHeader();
        return await ApiService.post("review/review_user_all", params)
            .then((result) => {
                // console.log(result)
                // result = result.data;
                return result;
            })
            .catch(({ response }) => {
                // console.log('error response', response.data);
                return response.data;
            });
    },
    async toEveryoneCheck({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put(`review/review_user_all/${params.id}`, params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response.data);
                return response.data;
            });
    },

    async uploadPresentation({ commit }, params) {
        commit(SET_LOADING, true, { root: true });
        ApiService.setHeader();
        return await ApiService.post("review/review_trial", params)
    },

    async uploadFile({ commit }, params) {
        commit(SET_LOADING, true, { root: true });
        ApiService.setHeader();
        return await ApiService.post("backend/upload/files", params).then((result) => {
            result = result.data;
            return result;
        })
        .catch(({ response }) => {
            console.log('error response', response);
            return response;
        });
    },

    async goalApprove({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put(`review/review_user_set_target/${params.id}/approved`, params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({ response }) => {
                console.log('error response', response.data);
                return response.data;
            });
    },
    async userSetGoal({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put(`review/review_user_set_target/${params.id}`, params)
            // .then((result) => {
            //     result = result.data;
            //     return result;
            // })
            // .catch(({ response }) => {
            //     console.log('error response', response.data);
            //     return response.data;
            // });
    },
};

