export const SET_REVIEWS = "setReviews";
export const SET_REVIEWSALL = "setReviewsAll";
export const SET_REVIEW = "setReview";
export const SET_REVIEWS_TOTAL = "setReviewsTotal";
export const SET_REVIEWS_ALL_TOTAL = "setReviewsAllTotal";

export const SET_SUBORDINATES = "setSubordinates";
export const SET_SUBORDINATE = "setSubordinate";
export const SET_SUBORDINATES_TOTAL = "setSubordinatesTotal";

export const SET_LOADING = "setLoading";

export const SET_USERDASHBOARD = "setUserDashboard";
export const SET_RECONFIRMDASHBOARD = "setReconfirmDashboard";

export const SET_REVIEWTRIALS = "setReviewTrials";
export const SET_REVIEWTRIAL = "setReviewTrial";

export const SET_REVIEWTRIALSFINAL = "setReviewTrialsFinal";
export const SET_REVIEWTRIALFINAL = "setReviewTrialFinal";

export const SET_REVIEWTARGET = "setReviewTarget";
export const SET_REVIEWTARGETBYID = "setReviewTargetById";
export const SET_REVIEWLASTMONTHTARGET = "setReviewLastMonthTarget";

export const SET_REVIEWDEPTS = "setReviewDepts";
export const SET_REVIEWDEPT = "setReviewDept";
export const SET_REVIEWDEPTGOAL = "setReviewDeptGoal";
export const SET_REVIEWDEPTLASTMONTHGOAL = "setReviewDeptLastMonthGoal";

export const SET_EVERYONECOMMENTBYID = "setEveryoneCommentById";

export const SET_TO_ALL_USER = "setToAllUser"

export default {
  [SET_REVIEWS](state, reviews) {
    state.reviews = reviews;
  },
  [SET_REVIEWSALL](state, reviewsAll) {
    state.reviewsAll = reviewsAll;
  },
  [SET_REVIEW](state, review) {
    state.review = review;
  },
  [SET_REVIEWS_TOTAL](state, reviewsTotalCount) {
    state.reviewsTotalCount = reviewsTotalCount;
  },
  [SET_REVIEWS_ALL_TOTAL](state, reviewsAllTotalCount) {
    state.reviewsAllTotalCount = reviewsAllTotalCount;
  },

  [SET_SUBORDINATES](state, subordinates) {
    state.subordinates = subordinates;
  },
  [SET_SUBORDINATE](state, subordinate) {
    state.subordinate = subordinate;
  },
  [SET_SUBORDINATES_TOTAL](state, totalCount) {
    state.subordinatesTotalCount = totalCount;
  },

  [SET_USERDASHBOARD](state, userDashboard) {
    state.userDashboard = userDashboard;
  },
  [SET_RECONFIRMDASHBOARD](state, reconfirmDashboard) {
    state.reconfirmDashboard = reconfirmDashboard;
  },

  [SET_REVIEWTRIALS](state, reviewTrials) {
    state.reviewTrials = reviewTrials;
  },
  [SET_REVIEWTRIAL](state, reviewTrial) {
    state.reviewTrial = reviewTrial;
  },

  [SET_REVIEWTRIALSFINAL](state, reviewTrialsFinal) {
    state.reviewTrialsFinal = reviewTrialsFinal;
  },
  [SET_REVIEWTRIALFINAL](state, reviewTrialFinal) {
    state.reviewTrialFinal = reviewTrialFinal;
  },

  [SET_REVIEWTARGET](state, reviewTarget) {
    state.reviewTarget = reviewTarget;
  },
  [SET_REVIEWTARGETBYID](state, reviewTargetById) {
    state.reviewTargetById = reviewTargetById;
  },
  [SET_REVIEWLASTMONTHTARGET](state, reviewLastMonthTarget) {
    state.reviewLastMonthTarget = reviewLastMonthTarget;
  },

  [SET_REVIEWDEPTS](state, reviewDepts) {
    state.reviewDepts = reviewDepts;
  },
  [SET_REVIEWDEPT](state, reviewDept) {
    state.reviewDept = reviewDept;
  },
  [SET_REVIEWDEPTGOAL](state, reviewDeptGoal) {
    state.reviewDeptGoal = reviewDeptGoal;
  },
  [SET_REVIEWDEPTLASTMONTHGOAL](state, reviewDeptLastMonthGoal) {
    state.reviewDeptLastMonthGoal = reviewDeptLastMonthGoal;
  },

  [SET_EVERYONECOMMENTBYID](state, everyoneCommentById) {
    state.everyoneCommentById = everyoneCommentById;
  },

  [SET_TO_ALL_USER](state, toAllUser) {
    state.toAllUser = toAllUser;
  },

  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
