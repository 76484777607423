export const SET_CONTRACT_NoS = "setContractNos";
export const SET_CONTRACT_NoS_TOTAL = "setContractNosTotal";
export const SET_CONTRACT_No = "setContractNo";
export const SET_LOADING = "setLoading";

export default {
  [SET_CONTRACT_NoS](state, contract_nos) {
    state.contract_nos = contract_nos;
  },
  [SET_CONTRACT_No](state, contract_no) {
    state.contract_no = contract_no;
  },
  [SET_CONTRACT_NoS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
