export const SET_VENDER_TYPES = "setVenderTypes";
export const SET_VENDER_TYPES_TOTAL = "setVenderTypesTotal";
export const SET_LOADING = "setLoading";

export default {
  [SET_VENDER_TYPES](state, vender_types) {
    state.vender_types = vender_types;
  },
  [SET_VENDER_TYPES_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
