import { SET_DASHBOARD, SET_LOADING, SET_ORDER, SET_ORDER_LIST, SET_PAGE } from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchDashboard({ commit }, params = {}) {
    commit(SET_LOADING, true);

    ApiService.setHeader();
    return await ApiService.query("backend/dashboards", params)
      .then((result) => {
        result = result.data;
        // console.log(result)
        commit(SET_DASHBOARD, result ?? []);
        commit(SET_PAGE, result?.page ?? 1);
        commit(SET_LOADING, false);
        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response);
      });
  },
  async fetchDashboardOrder({ commit }, params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/dashboard/order", params)
      .then((result) => {
        result = result.data;
        commit(SET_ORDER, result.data ?? []);
        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response);
      });
  },
  async fetchDashboardOrderList({ commit }, params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/dashboard/order/list", params)
      .then((result) => {
        result = result.data;
        commit(SET_ORDER_LIST, result.data ?? []);
        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response);
      });
  },
};
