import {
  SET_COMPARE_YESTERDAY,
  SET_LOADING,
  SET_ME, SET_MEMBER_TOTAL, SET_ORDER_MEMBER,
  SET_USER, SET_USER_LOGS,
  SET_USERS,
  SET_USERS_TOTAL
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchUsers({ commit }, params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/users", params)
      .then((result) => {
        result = result.data;
        commit(SET_USERS, result?.data ?? []);
        commit(SET_USERS_TOTAL, result?.total ?? []);
        commit(SET_LOADING, false);

        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response);
      });
  },
  async fetchUserAdminKolWriters({ commit }, params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/users/show_admin_writer_kol", params)
      .then((result) => {
        result = result.data;
        commit(SET_USERS, result?.data ?? []);
        commit(SET_USERS_TOTAL, result?.total ?? []);
        commit(SET_LOADING, false);

        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response);
      });
  },
  async fetchDashboardUser({ commit }, params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/dashboard/user", params)
      .then((result) => {
        result = result.data;
        // console.log('result', result.data)
        commit(SET_MEMBER_TOTAL, result?.data?.memberTotal ?? 0);
        commit(SET_ORDER_MEMBER, result?.data.orderMember ?? 0);
        commit(SET_COMPARE_YESTERDAY, result?.data.compareYesterday ?? 0);

        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response);
      });
  },
  async fetchUser({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get("backend/users", params)
      .then((result) => {
        result = result.data;
        commit(SET_USER, result ?? {});
        commit(SET_LOADING, false);
        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response);
        commit(SET_USER, { success: false });
      });
  },
  async fetchMe({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get("backend/users/me", params)
      .then((result) => {
        result = result.data;
        commit(SET_ME, result ?? {});
        commit(SET_LOADING, false);
        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response);
      });
  },
  async createUser({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
    ApiService.setHeader();
    return await ApiService.post("backend/users", params)
      .then((result) => {
        return result.data;
      })
      .catch(({ response }) => {
        console.log("error response", response.data);
        return response.data;
      });
  },
    async createUserCode({ commit }, params) {
        commit(SET_LOADING, true, { root: true });
        ApiService.setHeader();
        return await ApiService.post("backend/users/"+params.user_id+"/createCode", params)
            .then((result) => {
                return result.data;
            })
            .catch(({ response }) => {
                console.log("error response", response.data);
                return response.data;
            });
    },
  async updateUser({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.put("backend/users/" + params.id, params)
      .then((result) => {
        result = result.data;

        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response.data);
        return response.data;
      });
  },
  async updateMe({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.put("backend/users/me", params)
      .then((result) => {
        result = result.data;

        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response.data);
        return response.data;
      });
  },

  async deleteUser({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.delete("backend/users/" + params)
      .then(async (result) => {})
      .catch((response) => {
        console.log("delete error response", response);
      });
  },
  async resetPassword({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get("backend/users/"+params.id+"/reset_password")
      .then((result) => {
        result = result.data;
        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response);
      });
  },
  async fetchUserLogs({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get("backend/users/"+params+"/logs")
      .then((result) => {
        result = result.data;
        commit(SET_USER_LOGS, result ?? []);

        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response);
      });
  },
  async exportUsers({ commit }, params={}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.export("backend/users/export")
      .then(async (result) => {
        // console.log(result.data);
        const blob = new Blob([result.data], { type: "application/xlsx" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = params.label;
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((response) => {
        console.log("error response", response);
      });
  },


};
