export const SET_FORMS = "setForms";
export const SET_FORMS_TOTAL = "setFormsTotal";
export const SET_FORM = "setForm";
export const SET_LOADING = "setLoading";

export default {
  [SET_FORMS](state, forms) {
    state.forms = forms;
  },
  [SET_FORMS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_FORM](state, form) {
    state.form = form;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
