export const SET_MESSAGES = "setMessages";
export const SET_MESSAGES_TOTAL = "setMessageTotal";
export const SET_MESSAGE = "setMessage";
export const SET_LOADING = "setLoading";

export default {
  [SET_MESSAGES](state, messages) {
    state.messages = messages;
  },
  [SET_MESSAGES_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_MESSAGE](state, proposal) {
    state.message = proposal;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
