export const SET_BUDGET = "setBudget";
export const SET_LOADING = "setLoading";


export default {
  [SET_BUDGET](state, budget) {
    state.budget = budget;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
