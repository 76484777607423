import {
  SET_TAGS,
  SET_TAG_TOTAL,
  SET_TAG,
  SET_LOADING
} from "./mutations";
import ApiService from "@/core/services/ApiService";
import { SET_SHIPMENT } from "@/modules/shipment/store/mutations";

export default {
  async fetchTags({ commit },  params = {}) {
    commit(SET_LOADING, true);

    ApiService.setHeader();
    return await ApiService.query("backend/tags",params)
        .then((result) => {
          result =result.data;
          commit(SET_TAGS, result?.data ?? []);
          commit(SET_TAG_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          console.log('error response', response);
        });


  },
  async fetchTag({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get("backend/tags/",params)
        .then((result) => {
          result =result.data;
          commit(SET_TAG, result?.data ?? {});
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          console.log('error response', response);
          commit(SET_TAG, { success: false });

        });

  },
  async createTag({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
      ApiService.setHeader();
      return await ApiService.post("backend/tags", params)
          .then((result) => {
              result = result.data;
              commit(SET_TAG, result?.data ?? []);
              commit(SET_LOADING, false);

              return result;
          })
          .catch(({response}) => {
              console.log('error response', response.data);
              return response.data;
          });
  },
  async updateTag({ commit }, params) {
    commit(SET_LOADING, true);
      ApiService.setHeader();
      return await ApiService.put("backend/tags/"+params.id, params)
          .then((result) => {
              result = result.data;

              return result;
          })
          .catch(({response}) => {
              console.log('error response', response.data);
              return response.data;
          });
  },

  async deleteTag({ commit }, params) {
    commit(SET_LOADING, true);
      ApiService.setHeader();
      return await ApiService.delete("backend/tags/"+params)
          .then(async (result) => {

          })
          .catch((response) => {
              console.log('delete error response', response);
          });
  },
};
