export const SET_INVOICES = "setInvoices";
export const SET_INVOICES_TOTAL = "setInvoicesTotal";
export const SET_INVOICE = "setInvoice";
export const SET_LOADING = "setLoading";

export default {
  [SET_INVOICES](state, mgmt_invoices) {
    state.mgmt_invoices = mgmt_invoices;
  },
  [SET_INVOICES_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_INVOICE](state, mgmt_invoice) {
    state.mgmt_invoice = mgmt_invoice;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
