import {
    SET_LOADING,
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async uploadFile({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
    ApiService.setHeader();
    return await ApiService.post("backend/upload/files", params)
        .then((result) => {
          result = result.data;
          return result;
        })
        .catch(({response}) => {
          console.log('error response', response.data);
          return response.data;
        });
  },
};
