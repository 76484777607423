export const SET_QUOTATION = "setVenderTypes";
export const SET_QUOTATION_TOTAL = "setVenderTypesTotal";
export const SET_PM_LIST = "setPMList";
export const SET_PROPOSAL_LIST = "setProposalList"
export const SET_PLAN_LIST = "setPlanList"
export const SET_QUOTATION_FORM_DATA = "setQuotationFormData"
export const SET_QUOTATIONDATA = 'setQuotationdata'
export const SET_LOADING = "setLoading";

export const SET_PAYMENT_LIST = "setPaymentList"


export default {
  [SET_QUOTATION](state, quotation) {
    state.quotationList = quotation;
  },
  [SET_QUOTATION_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_PM_LIST](state, pmList){
    state.pmList = pmList
  },
  [SET_PAYMENT_LIST](state, paymentList){
    state.paymentList = paymentList
  },
  [SET_PROPOSAL_LIST](state, proposalList){
    state.proposalList = proposalList
  },
  [SET_PLAN_LIST](state, planList){
    state.planList = planList
  },
  [SET_QUOTATION_FORM_DATA](state, formData){
    state.quotationFormData = formData
  },
  [SET_QUOTATIONDATA](state, formData){
    state.quotationData = formData
  },
};
