
export const SET_PROPOSAL_DASHBOARD = "setProposalDashboard";
export const SET_PROPOSAL_ORDER = "setProposalOrder";
export const SET_ORDER_LIST = "setOrderList";

export const SET_LOADING = "setLoading";

export default {
  [SET_PROPOSAL_DASHBOARD](state, proposal_dashboard) {
    state.proposal_dashboard = proposal_dashboard;
  },
  [SET_PROPOSAL_ORDER](state, proposal_order) {
    state.proposal_order = proposal_order;
  },
  [SET_ORDER_LIST](state, order_list) {
    state.order_list = order_list;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
