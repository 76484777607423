export default {
  loading: false,
  learningCategories: [],
  learningCategory: [],
  learningCategoriesTotal: 0,
  learnings: [],
  learning_logs: [],
  learning: {},
  totalCount: 0,
};
