export const SET_ANNOUNCEMENTS = "setAnnouncements";
export const SET_ANNOUNCEMENTS_TOTAL = "setAnnouncementsTotal";
export const SET_ANNOUNCEMENT = "setAnnouncement";
export const SET_LOADING = "setLoading";

export default {
  [SET_ANNOUNCEMENTS](state, announcements) {
    state.announcements = announcements;
  },
  [SET_ANNOUNCEMENTS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_ANNOUNCEMENT](state, announcement) {
    state.announcement = announcement;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
