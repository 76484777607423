const ID_TOKEN_KEY = "id_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};


export const addUrlToken = (url): string => {
  url = new URL(url);

  if (url.searchParams.has('token')) {
    url=url+"&access="+getToken()
  }else{
    url=url+"?access="+getToken()
  }
  return url;
};

export default { addUrlToken,getToken, saveToken, destroyToken };
