export const SET_MEMBERS = "setMembers";
export const SET_MEMBER_TOTAL = "setMembersTotal";
export const SET_MEMBER = "setMember";
export const SET_LOADING = "setLoading";

export default {
  [SET_MEMBERS](state, members) {
    state.members = members;
  },
  [SET_MEMBER_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_MEMBER](state, member) {
    state.member = member;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
