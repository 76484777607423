export const SET_INDEX_CACHE = "setIndexCache";
export const SET_LOADING = "setLoading";

export default {
  [SET_INDEX_CACHE](state, index_cache) {
    state.index_cache = index_cache;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
