export const SET_LOCALE = "setLocale";
export const SET_LOADING = "setLoading";


export default {
  [SET_LOCALE](state, locales) {
    state.locales = locales;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
