export const SET_MGMT_CONTRACT_NoS = "setMgmtContractNos";
export const SET_MGMT_CONTRACT_NoS_TOTAL = "setMgmtContractNosTotal";
export const SET_MGMT_CONTRACT_No = "setContractNo";
export const SET_LOADING = "setLoading";

export default {
  [SET_MGMT_CONTRACT_NoS](state, mgmt_contract_nos) {
    state.mgmt_contract_nos = mgmt_contract_nos;
  },
  [SET_MGMT_CONTRACT_No](state, mgmt_contract_no) {
    state.mgmt_contract_no = mgmt_contract_no;
  },
  [SET_MGMT_CONTRACT_NoS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
