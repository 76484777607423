export const SET_USERS = "setUsers";
export const SET_USER_LOGS = "setUserLogs";
export const SET_USERS_TOTAL = "setUsersTotal";
export const SET_USER = "setUser";
export const SET_ME = "setMe";
export const SET_LOADING = "setLoading";
export const SET_MEMBER_TOTAL = "setMemberTotal";
export const SET_ORDER_MEMBER = "setOrderMember";
export const SET_COMPARE_YESTERDAY = "setCompareYesterday";

export default {
  [SET_USERS](state, users) {
    state.users = users;
  },
  [SET_USER_LOGS](state, user_logs) {
    state.user_logs = user_logs;
  },
  [SET_USERS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_USER](state, user) {
    state.user = user;
  },
  [SET_ME](state, me) {
    state.me = me;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_MEMBER_TOTAL](state, memberTotal) {
    state.memberTotal = memberTotal;
  },
  [SET_ORDER_MEMBER](state, orderMember) {
    state.orderMember = orderMember;
  },
  [SET_COMPARE_YESTERDAY](state, compareYesterday) {
    state.compareYesterday = compareYesterday;
  },
};
