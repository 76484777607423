export default {
  loading: false,
  locales:  [
    {value:'en-US',label:'英文'},
    {value:'zh-TW',label:'中文'},
    {value:'ms',label:'馬來文'},
    {value:'th',label:'泰文'},
    {value:'ja',label:'日文'}
  ],
  totalCount: 0,
};
