export const SET_DEPARTMENTS = "setDepartments";
export const SET_DEPARTMENTS_TOTAL = "setDepartmentsTotal";
export const SET_LOADING = "setLoading";

export default {
  [SET_DEPARTMENTS](state, departments) {
    state.departments = departments;
  },
  [SET_DEPARTMENTS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
