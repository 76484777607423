export default {
  loading: false,
  configLists: [],
  mgmt_status_list: [
    {
      label: "所有狀態",
      value: null,
    },
    {
      label: "草稿",
      value: 0,
    },
    {
      label: "審核中",
      value: 1,
    },
    {
      label: "通過審核",
      value: 2,
    },
    {
      label: "退審",
      value: 3,
    },
    {
      label: "結案",
      value: 4,
    },
  ],
  mgmt_review_list:  [
    {
      label: "所有簽核狀態",
      value: null,
    },
    {
      label: "未簽核: 流程已輪到我，但我還沒簽核",
      value: 0,
    },
    {
      label: "已簽核: 流程已輪到我，我已簽核",
      value: 1,
    }
  ],
  totalCount: 0,
};
