export default {
  loading: false,
  serviceForms: [],
  serviceForm: {},
  totalCount: 0,
  page:1,
  csNote:{},
  bankOptions: [
    { value: '', text: '選擇銀行...' },
    { value: '004', text: '004 臺灣銀行' },
    { value: '005', text: '005 臺灣土地銀行' },
    { value: '006', text: '006 合作金庫商業銀行' },
    { value: '007', text: '007 第一商業銀行' },
    { value: '008', text: '008 華南商業銀行' },
    { value: '009', text: '009 彰銀商業銀行' },
    { value: '011', text: '011 上海商業儲蓄銀行' },
    { value: '012', text: '012 台北富邦商業銀行' },
    { value: '013', text: '013 國泰世華商業銀行' },
    { value: '016', text: '016 高雄銀行' },
    { value: '017', text: '017 兆豐國際商業銀行' },
    { value: '021', text: '021 花旗(台灣)商業銀行' },
    { value: '024', text: '024 美國運通銀行' },
    { value: '025', text: '025 首都銀行' },
    { value: '039', text: '039 荷蘭銀行' },
    { value: '040', text: '040 中華開發' },
    { value: '048', text: '048 王道商業銀行' },
    { value: '050', text: '050 臺灣中小企業銀行' },
    { value: '052', text: '052 渣打國際商業銀行' },
    { value: '053', text: '053 台中商業銀行' },
    { value: '054', text: '054 京城商業銀行' },
    { value: '057', text: '057 台東區中小企業銀行' },
    { value: '072', text: '072 德意志銀行' },
    { value: '073', text: '073 東亞銀行' },
    { value: '081', text: '081 滙豐(台灣)商業銀行' },
    { value: '101', text: '101 稻江商銀' },
    { value: '102', text: '102 華泰商業銀行' },
    { value: '103', text: '103 臺灣新光商業銀行' },
    { value: '104', text: '104 台北市第五信用合作社' },
    { value: '106', text: '106 台北市第九信用合作社' },
    { value: '108', text: '108 陽信商業銀行' },
    { value: '114', text: '114 基隆第一信用合作社' },
    { value: '115', text: '115 基隆第二信用合作社' },
    { value: '118', text: '118 板信商業銀行' },
    { value: '119', text: '119 淡水第一信用合作社' },
    { value: '120', text: '120 淡水信用合作社' },
    { value: '124', text: '124 宜蘭信用合作社' },
    { value: '127', text: '127 桃園信用合作社' },
    { value: '130', text: '130 新竹第一信用合作社' },
    { value: '132', text: '132 新竹第三信用合作社' },
    { value: '139', text: '139 苗栗縣竹南信用合作社' },
    { value: '146', text: '146 台中市第二信用合作社' },
    { value: '147', text: '147 三信商業銀行' },
    { value: '158', text: '158 彰化第一信用合作社' },
    { value: '161', text: '161 彰化第五信用合作社' },
    { value: '162', text: '162 彰化第六信用合作社' },
    { value: '163', text: '163 彰化第十信用合作社' },
    { value: '165', text: '165 彰化縣鹿港信用合作社' },
    { value: '178', text: '178 嘉義市第三信用合作社' },
    { value: '179', text: '179 嘉義市第四信用合作社' },
    { value: '188', text: '188 台南第三信用合作社' },
    { value: '203', text: '203 高雄第二信用合作社' },
    { value: '204', text: '204 高雄第三信用合作社' },
    { value: '215', text: '215 花蓮第一信用合作社' },
    { value: '216', text: '216 花蓮第二信用合作社' },
    { value: '222', text: '222 澎湖縣第一信用合作社' },
    { value: '223', text: '223 澎湖縣第二信用合作社' },
    { value: '224', text: '224 金門縣信用合作社' },
    { value: '700', text: '700 中華郵政' },
    { value: '803', text: '803 聯邦商業銀行' },
    { value: '804', text: '804 滙豐銀行(原中華銀行)' },
    { value: '805', text: '805 遠東國際商業銀行' },
    { value: '806', text: '806 元大商業銀行' },
    { value: '807', text: '807 永豐商業銀行' },
    { value: '808', text: '808 玉山商業銀行' },
    { value: '809', text: '809 凱基商業銀行' },
    { value: '810', text: '810 星展(台灣)商業銀行' },
    { value: '812', text: '812 台新國際商業銀行' },
    { value: '814', text: '814 大眾商業銀行' },
    { value: '815', text: '815 日盛國際商業銀行' },
    { value: '816', text: '816 安泰商業銀行' },
    { value: '822', text: '822 中國信託商業銀行' },
    { value: '823', text: '823 將來商業銀行' },
    { value: '824', text: '824 連線商業銀行' },
    { value: '825', text: '825 慶豐商業銀行' },
    { value: '826', text: '826 樂天國際商業銀行' },
  ],
  paymentMethodObject: {
    CREDIT: '信用卡一次付清',
    CREDIT_INST: '國內信用卡分期',
    LINEPAY: 'LINEPAY',
    LINE_PAY: 'LINEPAY',
    CVS: '超商代碼',
    VACC: '虛擬ATM',
    UNIONPAY: '銀聯卡',
    EPOS_CREDIT: '信用卡一次付清', // 國泰信用卡一次付清
    EPOS_CREDIT_INST: '國內信用卡分期', // 國泰國內信用卡分期
    EPOS_UNIONPAY: '銀聯卡', // 國泰銀聯卡
  },
};
