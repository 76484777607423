export const SET_INVOICES = "setInvoices";
export const SET_INVOICES_TOTAL = "setInvoicesTotal";
export const SET_LOADING = "setLoading";

export default {
  [SET_INVOICES](state, invoices) {
    state.invoices = invoices;
  },
  [SET_INVOICES_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
