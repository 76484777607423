import {
    SET_LOADING, SET_ROLE, SET_ROLES, SET_ROLES_TOTAL,
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchRoles({ commit },  params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/roles",params)
        .then((result) => {
          result =result.data;
          commit(SET_ROLES, result?.data ?? []);
          commit(SET_ROLES_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          console.log('error response', response);
        });
  },
  async fetchRole({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get("backend/roles",params)
        .then((result) => {
          commit(SET_ROLE, result?.data ?? {});
          commit(SET_LOADING, false);
          return result;
        })
        .catch(({response}) => {
          console.log('error response', response);
          commit(SET_ROLE, { success: false });

        });

  },
  async createRole({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
    ApiService.setHeader();
    return await ApiService.post("backend/roles", params)
        .then((result) => {
          result = result.data;
          return result;
        })
        .catch(({response}) => {
          console.log('error response', response.data);
          return response.data;
        });
  },
  async updateRole({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    // console.log('params',params)
    return await ApiService.put("backend/roles/"+params.id, params)
        .then((result) => {
          result = result.data;

          return result;
        })
        .catch(({response}) => {
          console.log('error response', response.data);
          return response.data;
        });
  },

  async deleteRole({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.delete("backend/roles/"+params)
        .then(async (result) => {
        })
        .catch((response) => {
          console.log('delete error response', response);
        });
  },
};
