import { createApp } from 'vue';
import { createPinia } from "pinia";
import { Tooltip } from "bootstrap";
import App from "./App.vue";
import store from "@/stores";
import * as Sentry from "@sentry/vue";
import { createI18n } from "vue-i18n";
import zh from "./language/zh-TW.json";
import en from "./language/en-US.json";
/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import ElementPlus from "element-plus";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import { initKtIcon } from "@/core/plugins/keenthemes";
import vue3TreeOrg from 'vue3-tree-org';
import "vue3-tree-org/lib/vue3-tree-org.css";

import "@/core/plugins/prismjs";
const app = createApp(App);

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { createGtm } from 'vue-gtm';

const i18n = createI18n({
  legacy: false,
  locale:localStorage.getItem("locale") ?? "zh-TW",
  fallbackLocale: "zh-TW",
  messages: {
     zh,
     en,
  }
});

app.use(i18n);
app.use(VueSweetalert2)
app.use(Toast);

app.use(createPinia());
app.use(router);
app.use(ElementPlus);
app.use(store);
app.use(vue3TreeOrg)

app.use(createGtm({
  id: 'GTM-KGMW96LD',
  debug: true,
  vueRouter: router,
  trackOnNextTick: false,
}));

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initKtIcon(app);
initVeeValidate();

app.use(i18n);

app.directive("tooltip", (el) => {
  new Tooltip(el);
});

Sentry.init({
  app,
  dsn: "https://7d1316b3e0cd17384180eb7e656932e7@o4508022215737344.ingest.us.sentry.io/4508260509483008",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


app.mount("#app");
