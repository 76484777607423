import {
    SET_LOADING,
    SET_CATEGORY,
    SET_CATEGORIES, SET_CATEGORIES_TOTAL,
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchCategories({ commit }, params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/categories", params)
      .then((result) => {
        result = result.data;
        commit(SET_CATEGORIES, result?.data ?? []);
        commit(SET_CATEGORIES_TOTAL, result?.total ?? []);
        commit(SET_LOADING, false);

        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response);
      });
  },
  async fetchCategory({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/categories/"+params.id)
      .then((result) => {
        result = result.data;
        commit(SET_CATEGORY, result);
        commit(SET_LOADING, false);

        return result;
      })
      .catch(({ response }) => {
        console.log("error response", response);
      });
  },
};
