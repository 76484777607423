export const SET_CONFIGLISTS = "setConfiglists";
export const SET_CONFIGLISTS_TOTAL = "setConfiglistsTotal";
export const SET_LOADING = "setLoading";

export default {
  [SET_CONFIGLISTS](state, configLists) {
    state.configLists = configLists;
  },
  [SET_CONFIGLISTS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
