export const SET_TAGS = "settags";
export const SET_TAG_TOTAL = "setTagTotal";
export const SET_TAG = "setTag";
export const SET_LOADING = "setLoading";

export default {
  [SET_TAGS](state, tags) {
    state.tags = tags;
  },
  [SET_TAG_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_TAG](state, tag) {
    state.tag = tag;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
