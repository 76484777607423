export const SET_QUOTATIONS = "setQuotations";
export const SET_QUOTATIONS_TOTAL = "setQuotationsTotal";
export const SET_QUOTATION = "setQuotation";
export const SET_LOADING = "setLoading";

export default {
  [SET_QUOTATIONS](state, mgmt_quotations) {
    state.mgmt_quotations = mgmt_quotations;
  },
  [SET_QUOTATIONS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_QUOTATION](state, mgmt_quotation) {
    state.mgmt_quotation = mgmt_quotation;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
