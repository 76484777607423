import {
    SET_LOADING, SET_DEPARTMENTS_TOTAL,SET_DEPARTMENT,SET_DEPARTMENTS
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchMgmtDepartmentForms({ commit },  params = {}) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("mgmt/department_request",params)
        .then((result) => {
          result =result.data;
          commit(SET_DEPARTMENTS, result?.data ?? []);
          commit(SET_DEPARTMENTS_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          console.log('error response', response);
          commit(SET_LOADING, false);
        });
  },
    async fetchMgmtDepartmentFormExport({ commit },  params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.export("mgmt/department_request/export",params)
            .then((result) => {
                console.log(result.data)
                const blob = new Blob([result.data], { type: "application/xlsx" });
                const link = document.createElement("a");
                link.href = URL.createObjectURL(blob);
                link.download = params.label;
                link.click();
                URL.revokeObjectURL(link.href);
            })
            .catch(({response}) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
  async fetchMgmtDepartmentForm({ commit }, id) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get(`mgmt/department_request/${id}`)
        .then((result) => {
          commit(SET_DEPARTMENT, result?.data ?? {});
          commit(SET_LOADING, false);
          return result;
        })
        .catch(({response}) => {
          console.log('error response', response);
          commit(SET_DEPARTMENT, { success: false });

        });

  },
  async updateMgmtDepartmentForm({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.put("mgmt/department_request/"+params.apply_form_id, params)
        .then((result) => {
          result = result.data;

          return result;
        })
        .catch(({response}) => {
          console.log('error response', response.data);
          return response.data;
        });
  },
    async updateMgmtDepartmentApproveDesc({ commit }, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put("mgmt/department_request/"+params.id+"/update_approve_desc", params)
            .then((result) => {
                result = result.data;

                return result;
            })
            .catch(({response}) => {
                console.log('error response', response.data);
                return response.data;
            });
    },
};
