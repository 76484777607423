import user from "../modules/user/store";
import tag from "../modules/tag/store";
import service_form from "../modules/service_form/store";
import role from "../modules/role/store";
import setting from "../modules/setting/store";
import proposal from "../modules/proposal/store";
import member from "../modules/member/store";
import dashboard from "../modules/dashboard/store";
import proposal_dashboard from "../modules/proposal_dashboard/store";
import permission from "../modules/permission/store";
import locale from "../modules/locale/store";
import message from "../modules/message/store";
import form from "../modules/form/store";
import vender_types from "../modules/vender_types/store";
import stamp_types from "../modules/stamp_types/store";
import quotation from "../modules/quotation/store";
import upload from "../modules/upload/store";
import config_lists from "../modules/config_lists/store";
import approve_lists from "../modules/approve_lists/store";
import department from "../modules/department/store";
import announcement from "../modules/announcement/store";
import trialBalance from "../modules/trialBalance/store";
import organize from "../modules/organize/store";
import learning from "../modules/learning/store";
import mgmt_account from "../modules/mgmt_account/store";
import mgmt_credit from "../modules/mgmt_credit/store";
import mgmt_department from "../modules/mgmt_department/store";
import mgmt_invoice from "../modules/mgmt_invoice/store";
import mgmt_quotation from "../modules/mgmt_quotation/store";
import mgmt_stamp from "../modules/mgmt_stamp/store";
import index from "../modules/index/store";
import invoice from "../modules/invoice/store";
import category from "../modules/category/store";
import stamp from "../modules/stamp/store";
import contract_no from "../modules/contract_no/store";
import mgmt_contract_no from "../modules/mgmt_contract_no/store";
import review from "../modules/review/store";

const storeData = {
  modules: {
    user,
    tag,
    service_form,
    role,
    setting,
    proposal,
    member,
    dashboard,
    proposal_dashboard,
    permission,
    locale,
    message,
    form,
    vender_types,
    stamp_types,
    stamp,
    upload,
    quotation,
    config_lists,
    approve_lists,
    announcement,
    department,
    trialBalance,
    organize,
    learning,
    mgmt_account,
    mgmt_credit,
    mgmt_department,
    mgmt_invoice,
    mgmt_quotation,
    mgmt_stamp,
    index,
    invoice,
    category,
    contract_no,
    mgmt_contract_no,
    review
  },
};

export default storeData.modules;
