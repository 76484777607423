export const SET_STAMPS = "setStamps";
export const SET_STAMPS_TOTAL = "setStampsTotal";
export const SET_STAMP = "setStamp";
export const SET_LOADING = "setLoading";

export default {
  [SET_STAMPS](state, stamps) {
    state.stamps = stamps;
  },
  [SET_STAMPS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_STAMP](state, stamp) {
    state.stamp = stamp;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
