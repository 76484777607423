
export const SET_DASHBOARD = "setDashboard";
export const SET_ORDER = "setOrder";
export const SET_ORDER_LIST = "setOrderList";

export const SET_DASHBOARD_TOTAL = "setDashboardTotal";
export const SET_LOADING = "setLoading";
export const SET_PAGE = "setPage";

export default {
  [SET_DASHBOARD](state, dashboard) {
    state.dashboard = dashboard;
  },
  [SET_ORDER](state, order) {
    state.order = order;
  },
  [SET_ORDER_LIST](state, order_list) {
    state.order_list = order_list;
  },
  [SET_DASHBOARD_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_PAGE](state, page) {
    state.page = page;
  },
};
