
export const SET_CATEGORIES = "setCategories";
export const SET_CATEGORIES_TOTAL = "setCategoriesTotal";
export const SET_CATEGORY = "seCategory";
export const SET_LOADING = "setLoading";

export default {
  [SET_CATEGORIES](state, categories) {
    state.categories = categories;
  },
  [SET_CATEGORY](state, category) {
    state.category = category;
  },
  [SET_CATEGORIES_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
