export const SET_PROPOSALS = "setProposals";
export const SET_PROPOSALS_TOTAL = "setProposalsTotal";
export const SET_PROPOSAL = "setProposal";
export const SET_LOADING = "setLoading";
export const SET_PROPOSALS_CATE = "setProposalCategory";
export const SET_PROPOSALS_TYPE = "setProposalType";

export default {
  [SET_PROPOSALS](state, proposals) {
    state.proposals = proposals;
  },
  [SET_PROPOSALS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_PROPOSAL](state, proposal) {
    state.proposal = proposal;
  },
  [SET_PROPOSALS_CATE](state, proposal) {
    state.proposalCategory = proposal;
  },
  [SET_PROPOSALS_TYPE](state, proposal) {
    state.proposalType = proposal;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
