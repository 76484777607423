export const SET_PERMISSIONS = "setPermissions";
export const SET_PERMISSIONS_TOTAL = "setPermissionsTotal";
export const SET_PERMISSION = "setPermission";
export const SET_LOADING = "setLoading";

export default {
  [SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions;
  },
  [SET_PERMISSIONS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_PERMISSION](state, permission) {
    state.permission = permission;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
