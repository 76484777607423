export default {
  loading: false,
  users: [],
  user_logs: [],
  user: {},
  me: {},
  totalCount: 0,
  memberTotal: 0,
  orderMember: 0,
  compareYesterday: 0,

};
