export const SET_LEARNING_CATEGORIES = "setLearningCategories";
export const SET_LEARNING_CATEGORY = "setLearningCategory";
export const SET_LEARNINGS_CATEGORIES_TOTAL = "setLearningCategoriesTotal";
export const SET_LEARNINGS = "setLearnings";
export const SET_LEARNINGS_TOTAL = "setLearningsTotal";
export const SET_LEARNING = "setLearning";
export const SET_LOADING = "setLoading";

export default {
  [SET_LEARNING_CATEGORIES](state, learningCategories) {
    state.learningCategories = learningCategories;
  },
  [SET_LEARNING_CATEGORY](state, learningCategory) {
    state.learningCategory = learningCategory;
  },
  [SET_LEARNINGS_CATEGORIES_TOTAL](state, learningCategoriesTotal) {
    state.learningCategoriesTotal = learningCategoriesTotal;
  },
  [SET_LEARNINGS](state, learnings) {
    state.learnings = learnings;
  },
  [SET_LEARNINGS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_LEARNING](state, learning) {
    state.learning = learning;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
