import {SET_LOADING, SET_STAMP_TYPES, SET_STAMP_TYPES_TOTAL,} from "./mutations";
import ApiService from "@/core/services/ApiService";
import {SET_ORDERS, SET_ORDERS_TOTAL, SET_PAGE} from "@/modules/order/store/mutations";

export default {
    async fetchStampTypes({commit}, params = {}) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("backend/stamp_types", params)
            .then((result) => {
                result = result.data;
                commit(SET_STAMP_TYPES, result?.data ?? []);
                commit(SET_STAMP_TYPES_TOTAL, result?.total ?? []);
                commit(SET_LOADING, false);

                return result;
            })
            .catch(({response}) => {
                console.log('error response', response);
            });
    },
    async fetchStampType({commit}, params) {
        // console.log(params)
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("backend/stamp_types/" + params.id, params)
            .then((result) => {
                result = result.data;

                return result;
            })
            .catch(({response}) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
    async createStampType({commit}, params) {
        commit(SET_LOADING, true, {root: true});
        ApiService.setHeader();
        return await ApiService.post("backend/stamp_types", params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({response}) => {
                console.log('error response', response.data);
                return response.data;
            });
    },
    async updateStampType({commit}, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put("backend/stamp_types/" + params.id, params)
            .then((result) => {
                result = result.data;

                return result;
            })
            .catch(({response}) => {
                console.log('error response', response.data);
                return response.data;
            });
    },
    async deleteStampType({commit}, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.delete("backend/stamp_types/" + params)
            .then(async (result) => {
                console.log("deleteStampType", result);
                result = result.data;
                commit(SET_LOADING, false);
                return result;
            })
            .catch((response) => {
                console.log("delete error response", response);
            });
    },

};
