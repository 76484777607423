export default {
  loading: false,
  quotationList: [],
  totalCount: 0,
  pmList:[],
  proposalList:[],
  planList:[],
  quotationFormData:{},
  quotationData:{},
  paymentList:[],
};
