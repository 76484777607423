export const SET_ACCOUNTS = "setAccounts";
export const SET_ACCOUNTS_TOTAL = "setAccountsTotal";
export const SET_ACCOUNT = "setAccount";
export const SET_LOADING = "setLoading";

export default {
  [SET_ACCOUNTS](state, mgmt_accounts) {
    state.mgmt_accounts = mgmt_accounts;
  },
  [SET_ACCOUNTS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_ACCOUNT](state, mgmt_account) {
    state.mgmt_account = mgmt_account;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
