export default {
  loading: false,
  stamps: [],
  stamp: {},
  stamp_seal:[
    {label:'尚未選擇',value:'0'},
    {label:'登記章（政府用',value:'1'},
    {label:'銀行章（財務',value:'2'},
    {label:'合約章',value:'3'},
    {label:'發票章',value:'4'},
    {label:'代表人親簽',value:'5'},
  ],
  totalCount: 0,
};
