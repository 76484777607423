export const SET_ROLES = "setRoles";
export const SET_ROLES_TOTAL = "setRolesTotal";
export const SET_ROLE = "setRole";
export const SET_LOADING = "setLoading";

export default {
  [SET_ROLES](state, roles) {
    state.roles = roles;
  },
  [SET_ROLES_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_ROLE](state, role) {
    state.role = role;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
