import {
  SET_LOADING, SET_CONFIGLISTS, SET_CONFIGLISTS_TOTAL,
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {
  async fetchConfigLists({ commit },  params) {
    // console.log(params)
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.query("backend/config_lists",params)
        .then((result) => {
          result =result.data;
          commit(SET_CONFIGLISTS, result?.data ?? []);
          commit(SET_CONFIGLISTS_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          console.log('error response', response);
          commit(SET_LOADING, false);
        });
  },
    async fetchConfigList({commit}, params) {
        // console.log(params)
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.query("backend/config_lists/" + params.id, params)
            .then((result) => {
                result = result.data;

                return result;
            })
            .catch(({response}) => {
                console.log('error response', response);
                commit(SET_LOADING, false);
            });
    },
    async createConfigList({commit}, params) {
        commit(SET_LOADING, true, {root: true});
        ApiService.setHeader();
        return await ApiService.post("backend/config_lists", params)
            .then((result) => {
                result = result.data;
                return result;
            })
            .catch(({response}) => {
                console.log('error response', response.data);
                return response.data;
            });
    },
    async updateConfigList({commit}, params) {
        commit(SET_LOADING, true);
        ApiService.setHeader();
        return await ApiService.put("backend/config_lists/" + params.id, params)
            .then((result) => {
                result = result.data;

                return result;
            })
            .catch(({response}) => {
                console.log('error response', response.data);
                return response.data;
            });
    },
};
