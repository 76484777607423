
export const SET_SETTINGS = "setSettings";
export const SET_SETTINGS_TOTAL = "setSettingsTotal";
export const SET_SETTING = "setSetting";
export const SET_LOADING = "setLoading";
export const SET_PAGE = "setPage";


export default {
  [SET_SETTINGS](state, settings) {
    state.settings = settings;
  },
  [SET_SETTINGS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_SETTING](state, setting) {
    state.setting = setting;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_PAGE](state, page) {
    state.page = page;
  },
};
