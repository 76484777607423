export const SET_DEPARTMENTS = "setDepartments";
export const SET_DEPARTMENTS_TOTAL = "setDepartmentsTotal";
export const SET_DEPARTMENT = "setForm";
export const SET_LOADING = "setLoading";

export default {
  [SET_DEPARTMENTS](state, mgmt_departments) {
    state.mgmt_departments = mgmt_departments;
  },
  [SET_DEPARTMENTS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_DEPARTMENT](state, mgmt_department) {
    state.mgmt_department = mgmt_department;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
