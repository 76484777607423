import {
  SET_LOADING, SET_CONTRACT_NoS, SET_CONTRACT_No, SET_CONTRACT_NoS_TOTAL
} from "./mutations";
import ApiService from "@/core/services/ApiService";

export default {

  async fetchContractNos({ commit },  params = {}) {
    commit(SET_LOADING, true);

    ApiService.setHeader();
    return await ApiService.query("backend/contract_nos",params)
        .then((result) => {
          result =result.data;
          commit(SET_CONTRACT_NoS, result?.data ?? []);
          commit(SET_CONTRACT_NoS_TOTAL, result?.total ?? []);
          commit(SET_LOADING, false);

          return result;
        })
        .catch(({response}) => {
          console.log('error response', response);
        });
  },
  async fetchContractNo({ commit }, params) {
    commit(SET_LOADING, true);
    ApiService.setHeader();
    return await ApiService.get("backend/contract_nos", params)
        .then((result) => {
          result = result.data;
          commit(SET_CONTRACT_No, result ?? {});
          commit(SET_LOADING, false);
          return result;
        })
        .catch(({response}) => {
          console.log('error response', response.data);
          commit(SET_CONTRACT_No, { success: false });
            return response.data;

        });
  },
  async createContractNo({ commit }, params) {
    commit(SET_LOADING, true, { root: true });
      ApiService.setHeader();
      return await ApiService.post("backend/contract_nos", params)
          .then((result) => {
              return result.data;
          })
          .catch(({response}) => {
              console.log('error response', response);
              return response.data;
          });
  },
  async updateContractNo({ commit },params) {
    const id = params.proposal_id
    delete params.proposal_id
    commit(SET_LOADING, true);
      ApiService.setHeader();
      return await ApiService.put("backend/contract_nos/"+id, params)
          .then((result) => {
              return result.data;
          })
          .catch(({response}) => {
              console.log('error response', response);
          });
  },
  async deleteContractNo({ commit }, params) {
    commit(SET_LOADING, true);
      ApiService.setHeader();
      return await ApiService.delete("backend/contract_nos/"+params)
          .then(async (result) => {
              console.log('result',result.data)
              return result.data;

          })
          .catch((response) => {
              console.log('delete error response', response);
          });
  },
};
