export const SET_CREDITS = "setCredits";
export const SET_CREDITS_TOTAL = "setCreditsTotal";
export const SET_CREDIT = "setCredit";
export const SET_LOADING = "setLoading";

export default {
  [SET_CREDITS](state, mgmt_credits) {
    state.mgmt_credits = mgmt_credits;
  },
  [SET_CREDITS_TOTAL](state, totalCount) {
    state.totalCount = totalCount;
  },
  [SET_CREDIT](state, mgmt_credit) {
    state.mgmt_credit = mgmt_credit;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
