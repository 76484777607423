export const SET_SERVICE_FORMS = "setServiceForms";
export const SET_SERVICE_FORM_TOTAL = "setServiceFormTotal";
export const SET_SERVICE_FORM = "setServiceForm";
export const SET_CS_NOTE = "setCsNote";
export const SET_LOADING = "setLoading";

export default {
  [SET_SERVICE_FORMS](state, serviceForms) {
    state.serviceForms = serviceForms;
  },
  [SET_SERVICE_FORM_TOTAL](state, totalCount) {
    // console.log('mutation',totalCount);
    state.totalCount = totalCount;
  },
  [SET_SERVICE_FORM](state, serviceForm) {
    state.serviceForm = serviceForm;
  },
  [SET_CS_NOTE](state, csNote) {
    state.csNote = csNote;
  },
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
};
