const ID_TOKEN_KEY = "id_user" as string;
const ID_PERMISSION_KEY = "id_permission" as string;

/**
 * @description get token form localStorage
 */
export const getUser = (): string => {
    return <string>window.localStorage.getItem(ID_TOKEN_KEY);
};

export const getName = (): string => {
    const json = JSON.parse(<string>window.localStorage.getItem(ID_TOKEN_KEY));
    return json?.name;
};

export const getProposalIds = (): string => {
    const json = JSON.parse(<string>window.localStorage.getItem(ID_TOKEN_KEY));
    return json?.proposal_ids;
};

export const getUserId = (): string => {
    const json = JSON.parse(<string>window.localStorage.getItem(ID_TOKEN_KEY));
    return json?.id;
};

export const getPermission = (): string => {
    return <string>window.localStorage.getItem(ID_PERMISSION_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveUser = (token: string): void => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const savePermission = (token: string): void => {
    window.localStorage.setItem(ID_PERMISSION_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyUser = (): void => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyPermission = (): void => {
    window.localStorage.removeItem(ID_PERMISSION_KEY);
};

export default {getUser, saveUser, destroyUser, getPermission, savePermission, destroyPermission, getName, getUserId, getProposalIds};
