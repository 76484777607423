export const SET_LOADING = "setLoading";
export const SET_ORGANIZE = "setOrganize";
export const SET_DEPT = "setDept"
export const UPDATE_DEPT = "updateDept"
export const SET_SIGNER = "setSigner"

export default {
  [SET_LOADING](state, loading) {
    state.loading = loading;
  },
  [SET_ORGANIZE](state, organize) {
    state.organizeList = organize;
  },
  [SET_DEPT](state, dept){
    state.dept = dept
  },
  [UPDATE_DEPT](state, dept){
    state.updateDept = dept
  },
  [SET_SIGNER](state, dept){
    state.deptSigner = dept
  }
};
